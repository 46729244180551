<template>
  <div id="app" style="height: 100%">
<!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <index />
  </div>
</template>

<script>
import index from './page/index.vue'

export default {
  name: 'App',
  components: {
    index
  }
}
</script>

<style>
</style>
