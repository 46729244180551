import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
Vue.config.productionTip = false

// 引入 axios
import axios from 'axios'

// 挂载一个自定义属性$http
Vue.prototype.$http = axios
new Vue({
  render: h => h(App),
}).$mount('#app')
