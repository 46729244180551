import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 15000
})

service.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json'
  return config
}, error => Promise.reject(error))

service.interceptors.response.use(response => {
  return response.data
}, error => {
  return Promise.reject(error)
})

export default service
